<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
export default {
  page: {
    title: "แก้ไขข้อมูลรับรถเข้าคลัง",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      // tableData: tableData,

      title: "แก้ไขข้อมูลรับรถเข้าคลัง",
      items: [
        {
          text: "คลังสินค้า",
          active: true,
        },
        {
          text: "รับรถเข้าคลัง",
          active: false,
          href: "/received-vehicle",
        },
        {
          text: "แก้ไขข้อมูลรับรถเข้าคลัง",
          active: true,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      rowsProduct: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      optionBranch: [],
      rowsDetail: [],
      loading: false,
      userBranchAccessId: "",
      submitted: false,
      searchform: "",
      selectedPO: [],
      interiorColor: "",
      exteriorColor: "",
      optioninteriorColor: [],
      optionExteriorColor: [],

      userBranchId: "",
      rcvdDate: "",
      dueDate: "",
      credit: "",
      taxInvoiceDate: "",
      taxInvoiceNumber: "",
      shippingDate: "",
      shippingNumber: "",
      stock: "",
      supId: "",

      vin: "",
      chassisNumber: "",
      engineNumber: "",

      poItem: {
        prodId: "",
        nameTh: "",
        prodCode: "",
        pricePerOne: "",
        amount: 0,
        poTotalPrice: "",
        poDcPer: 0,
        poDcPrice: "",
        poNetPrice: "",
        prodNameTh: "",
      },
      optionStatus: [
        {
          name: "ยืนยันแล้ว",
          status: "APV",
        },
        {
          name: "ยกเลิกแล้ว",
          status: "CAN",
        },
      ],

      fieldsPartPO: [
        {
          key: "poDate",
          label: "วันที่(ใบสังซื้อ)",
          sortable: true,
        },
        {
          key: "poCode",
          label: "รหัสใบสั่งซื้่อ",
          sortable: true,
        },
        {
          key: "prodCode",
          sortable: true,
          label: "รหัสสินค้า",
        },
        {
          key: "prodNameTh",
          sortable: true,
          label: "ชื่อสินค้า",
        },
        // {
        //   key: "modelCode",
        //   sortable: true,
        //   label: "รหัสรุ่น",
        // },
        {
          key: "modelNameTh",
          sortable: true,
          label: "ชื่อรุ่น",
        },
        {
          key: "exteriorColorNameTh",
          sortable: true,
          label: "สีภายนอก",
        },
        {
          key: "interiorColorNameTh",
          sortable: true,
          label: "สีภายใน",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ราคารวม",
        },
        {
          key: "amount",
          label: "จำนวน",
          sortable: true,
        },
        {
          key: "accruedAmount",
          label: "คงค้าง",
          sortable: true,
        },
        {
          key: "action",
          label: "เพิ่ม",
        },
      ],
      filterOnPO: [],
      rowDataPO: [],
      perPagePO: 100,
      currentPagePO: 1,
      filterPO: null,
      pageOptionsPO: [5, 10, 25, 50, 100],
      total_pagesPO: "",
      pagePO: "",
      totalPO: "",

      poDate: "",
      status: "",

      poVatPer: "",
      poTotalPrice: "",
      poDcPer: 0,
      poDcPrice: 0,
      poNetPrice: "",
      poVatPrice: "",
      poGrandTotalPrice: "",

      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",

      productAmount: "",
      productPricePerOne: "",
      productTotalPrice: "",
      productDcPer: "",
      productDcPrice: "",
      productNetPrice: "",
      productVatPer: "",
      productVatPrice: "",
      productGrandTotalPrice: "",

      rowsStock: [],
      optionsPO: [],
      poCode: "",

      nameState: null,

      userBranch: "",
      prodId: "",
      filterOn: [],
      rowsPO: [],
      rowsSuppliers: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "poCode",
          sortable: true,
          label: "รหัสสั่งซื้อ",
        },
        {
          key: "supplierNameTh",
          sortable: true,
          label: "ผู้ผลิต",
        },
        {
          key: "poDate",
          sortable: true,
          label: "วันที่สั่งซื้อ",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ราคารวม",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "status",
        },
        {
          key: "action",
          label: appConfig.action,
          sortable: false,
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowsPO.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    const arrayBranchAll = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      arrayBranchAll.push(item);
    });
    this.userBranchAccessId = user.branchId;
    this.userBranch = arrayBranch;
    this.optionBranch = arrayBranchAll;
    this.totalRows = this.rowsPO.total;
    if (this.$route.params.rcvdId) {
      this.getDataDetail();
      this.getDetailProd();
      this.calculatePo();
    }
    // this.getBranchProduct();
    // this.getDataSuppliers();
    this.getBranchExterior();
    this.getBranchInterior();
  },
  created() {
    // this.getData();
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    numberFormats(x) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(x);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getDataDetail: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/received-vehicle/show", {
          params: {
            rcvdId: atob(this.$route.params.rcvdId),
          },
        })
        .then((response) => {
          this.userBranchId = {
            branchId: response.data.data.branchId,
            nameTh: response.data.data.branchName,
          };
          this.supId = {
            supId: response.data.data.supId,
            nameTh: response.data.data.supplierNameTh,
          };
          this.stock = {
            stockId: response.data.data.stockId,
            nameTh: response.data.data.stockNameTh,
          };
          this.shippingNumber = response.data.data.shippingNumber;
          this.shippingDate = response.data.data.shippingDate;
          this.taxInvoiceNumber = response.data.data.taxInvoiceNumber;
          this.taxInvoiceDate = response.data.data.taxInvoiceDate;
          this.credit = response.data.data.credit;
          this.dueDate = response.data.data.dueDate;
          this.rcvdDate = response.data.data.rcvdDate;
          this.status = response.data.data.status;
          this.poDate = response.data.data.poDate;
          this.poVatPer = response.data.data.vatPer;
          this.poDcPer = response.data.data.dcPer;
          this.poDcPrice = response.data.data.dcPrice;
          this.poGrandTotalPrice = response.data.data.grandTotalPrice;
          this.poNetPrice = response.data.data.netPrice;
          this.poTotalPrice = response.data.data.totalPrice;
          this.poVatPrice = response.data.data.vatPrice;
          //   this.rowsPO = response.data.data;
          //   this.rowsPO.to = response.data.to;
          //   this.rowsPO.from = response.data.from;
          //   this.rowsPO.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataStock: function() {
      this.loading = true;
      useNetw
        .get("api/master/stock-vehicle", {
          params: {
            branchId: this.userBranchId.branchId,
          },
        })
        .then((response) => {
          this.rowsStock = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          // this.isHidden = true;
        });
    },
    getFunction() {
      this.getDataSuppliers();
      this.getDataStock();
    },
    getDataSuppliers: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/master/suppliers", {
          params: {
            branchId:
              this.userBranchId != null
                ? this.userBranchId.branchId
                : undefined,
          },
        })
        .then((response) => {
          this.rowsSuppliers = response.data.data;
          this.rowsSuppliers.to = response.data.to;
          this.rowsSuppliers.from = response.data.from;
          this.rowsSuppliers.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDetailProd: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/received-vehicle/product", {
          params: {
            rcvdId: atob(this.$route.params.rcvdId),
          },
        })
        .then((response) => {
          this.rowsDetail = response.data.data;
          this.calculatePo();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getBranchProduct: function(nameTh) {
      useNetw
        .get("api/received-vehicle/branch-product", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.userBranchAccessId,
            image: 0,
            nameTh: nameTh,
            // this.userBranchAccessId != null
            //   ? this.userBranchId.branchId
            //   : undefined,
          },
        })
        .then((response) => {
          this.rowsProduct = response.data.data;
          // this.rowsDetail = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {});
    },
    getBranchExterior: function() {
      useNetw
        .get("api/received-vehicle/branch-exterior-color", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.userBranchAccessId,
            // this.userBranchAccessId != null
            //   ? this.userBranchId.branchId
            //   : undefined,
          },
        })
        .then((response) => {
          this.optionExteriorColor = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {});
    },
    getBranchInterior: function() {
      useNetw
        .get("api/received-vehicle/branch-interior-color", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.userBranchAccessId,
            // this.userBranchAccessId != null
            //   ? this.userBranchId.branchId
            //   : undefined,
          },
        })
        .then((response) => {
          this.optioninteriorColor = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {});
    },
    // submitVehiclePO() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .post("api/vehicle-po/store", {
    //       branchId:
    //         this.userBranchId != null ? this.userBranchId.branchId : undefined,
    //       supId: this.supId != null ? this.supId.supId : undefined,
    //       poDate: this.poDate,
    //       vatPer: this.vatPer,
    //     })
    //     .then((response) => {
    //       Swal.fire(
    //         appConfig.swal.title.postSuccess,
    //         JSON.stringify(response.data.message),
    //         appConfig.swal.type.success
    //       );
    //       this.$router.push({ name: "vehicle-po" });
    //       //   this.getData();
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },
    addDetailReceived(poItem) {
      this.calculateProduct(poItem);
      this.overlayFlag = true;
      useNetw
        .post("api/received-vehicle/product/store", {
          rcvdId: atob(this.$route.params.rcvdId),
          prodId: poItem.prodId,
          vin: this.vin,
          chassisNumber: this.chassisNumber,
          engineNumber: this.engineNumber,
          poId: this.poId,
          // amount: this.poItem.amount,
          pricePerOne: this.poItem.pricePerOne,
          totalPrice: this.poItem.poTotalPrice,
          dcPer: this.poItem.poDcPer,
          dcPrice: this.poItem.poDcPrice,
          netPrice: this.poItem.poNetPrice,
          vatPer: this.poVatPer,
          vatPrice: this.poItem.vatPrice,
          grandTotalPrice: this.poItem.poGrandTotalPrice,

          exteriorColorId: this.exteriorColor.exteriorColorId,
          interiorColorId: this.interiorColor.interiorColorId,

          rcvdTotalPrice: parseFloat(this.poTotalPrice) + poItem.totalPrice,
          rcvdDcPer: this.poDcPer,
          rcvdDcPrice: parseFloat(this.poDcPrice), // parseFloat(this.poDcPrice) + poItem.dcPrice,
          rcvdNetPrice: parseFloat(this.poNetPrice) + poItem.poNetPrice,
          rcvdVatPer: this.poVatPer,
          rcvdVatPrice: parseFloat(this.poVatPrice) + poItem.vatPrice,
          rcvdGrandTotalPrice:
            parseFloat(this.poGrandTotalPrice) + poItem.poGrandTotalPrice,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modalAmout"].hide();
          this.$refs["modalPO"].hide();
          this.getDataDetail();
          this.getDetailProd();
          this.vin = null;
          this.chassisNumber = null;
          this.engineNumber = null;
          // this.calculateProduct();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br />" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    updateReceived() {
      this.overlayFlag = true;
      useNetw
        .put("api/received-vehicle/update", {
          rcvdId: atob(this.$route.params.rcvdId),
          supId: this.supId != null ? this.supId.supId : undefined,
          rcvdDate: this.rcvdDate,
          stockId: this.stock.stockId,
          shippingNumber: this.shippingNumber,
          shippingDate: this.shippingDate,
          taxInvoiceNumber: this.taxInvoiceNumber,
          taxInvoiceDate: this.taxInvoiceDate,
          credit: this.credit,
          dueDate: this.dueDate,
          totalPrice: this.poTotalPrice,
          dcPer: this.poDcPer,
          dcPrice: this.poDcPrice,
          netPrice: this.poNetPrice,
          vatPer: this.poVatPer,
          vatPrice: this.poVatPrice,
          grandTotalPrice: this.poGrandTotalPrice,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataDetail();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    approveReceived() {
      this.overlayFlag = true;
      useNetw
        .put("api/received-vehicle/approve", {
          rcvdId: atob(this.$route.params.rcvdId),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataDetail();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    submitReceived() {
      this.overlayFlag = true;
      useNetw
        .put("api/received-vehicle/submit", {
          rcvdId: atob(this.$route.params.rcvdId),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataDetail();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    alert(poItem, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteReceived(poItem, index);
          }
        });
    },
    updateDetailReceived(poItem) {
      this.calculateProduct(poItem);
      this.overlayFlag = true;
      useNetw
        .put("api/received-vehicle/product/update", {
          rcvddId: poItem.rcvddId,
          prodId: poItem.prodId,
          // podId: poItem.podId,
          poId: poItem.poId != null ? poItem.poId : undefined,
          vin: poItem.vin,
          chassisNumber:
            poItem.chassisNumber != null ? poItem.chassisNumber : undefined,
          engineNumber:
            poItem.engineNumber != null ? poItem.engineNumber : undefined,
          // amount: poItem.amount,
          pricePerOne: poItem.pricePerOne,
          totalPrice: poItem.totalPrice,
          dcPer: poItem.dcPer,
          dcPrice: poItem.dcPrice,
          netPrice: poItem.netPrice,
          vatPer: poItem.vatPer,
          vatPrice: poItem.vatPrice,
          grandTotalPrice:
            parseFloat(poItem.netPrice) + parseFloat(poItem.vatPrice),

          exteriorColorId: this.exteriorColor.exteriorColorId,
          interiorColorId: this.interiorColor.interiorColorId,

          rcvdTotalPrice: parseFloat(this.poTotalPrice),
          rcvdDcPer: this.poDcPer,
          rcvdDcPrice: parseFloat(this.poDcPrice),
          rcvdNetPrice: parseFloat(this.poNetPrice),
          rcvdVatPer: this.poVatPer,
          rcvdVatPrice: parseFloat(this.poVatPrice),
          rcvdGrandTotalPrice: parseFloat(this.poGrandTotalPrice),
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // this.getDataDetail();
          this.getDetailProd();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    deleteReceived: function(poItem) {
      // this.rowsDetail.splice(index, 1);
      this.calculatePo(this.rowsDetail);

      this.loading = true; //skeleton true
      useNetw
        .delete("api/received-vehicle/product/delete", {
          data: {
            rcvddId: poItem.rcvddId,
            // prodId: this.poItem.prodId,
            rcvdTotalPrice: this.poTotalPrice - poItem.totalPrice,
            rcvdDcPer: this.poDcPer,
            rcvdDcPrice: this.poDcPrice,
            rcvdNetPrice: this.poNetPrice - poItem.netPrice,
            rcvdVatPer: this.poVatPer,
            rcvdVatPrice: this.poVatPrice - poItem.vatPrice,
            rcvdGrandTotalPrice:
              this.poGrandTotalPrice - poItem.grandTotalPrice,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          // this.getPoShow(this.poId);
          // this.rowPoParts.push(this.rowPoArr)
          this.getDetailProd();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alertSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันรับรถเข้าคลังหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.approveReceived();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    customLabel({ prodCode, nameTh }) {
      return `[${prodCode}] - ${nameTh != null ? nameTh : ""}`;
    },
    showModal(option) {
      this.selectedPO = option;
      this.poItem.prodId = option != null ? option.prodId : undefined;
      this.poItem.prodCode = option != null ? option.prodCode : undefined;
      this.poItem.pricePerOne = parseFloat(
        option != null ? option.price : undefined
      );
      // this.poItem.totalPrice = option.price;
      // this.poItem.netPrice = option.price;
      this.poItem.nameTh = option != null ? option.nameTh : undefined;
      // this.poItem.prodNameTh = option.item.prodNameTh;
      // this.poItem.prodCode = option.partId;
      // console.log(option,'option')
      this.$refs.modalAmout.show(option);
    },
    showModalPO(option) {
      this.exteriorColor = {exteriorColorId: option.item.exteriorColorId, nameTh: option.item.exteriorColorNameTh}
      this.interiorColor = {interiorColorId: option.item.interiorColorId, nameTh: option.item.interiorColorNameTh}
      this.selectedPO = option.item;
      this.poItem.prodId = option.item.prodId;
      this.poItem.prodCode = option.item.prodCode;
      this.poItem.pricePerOne = parseFloat(option.item.pricePerOne);
      // this.poItem.totalPrice = option.price;
      // this.poItem.netPrice = option.price;
      this.poItem.nameTh = option.item.prodNameTh;
      // this.poItem.prodNameTh = option.item.prodNameTh;
      // this.poItem.prodCode = option.partId;
      this.$refs.modalAmout.show(option.item);
    },
    calculatePo() {
      // console.log(poItem,'poItem')
      let poTotalPrice, poDcPrice, poNetPrice, poVatPrice, poGrandTotalPrice;

      poTotalPrice = this.rowsDetail.reduce(function(sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);

        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);

      this.poTotalPrice = poTotalPrice;
      poDcPrice =
        parseFloat(this.poTotalPrice) * parseFloat(this.poDcPer / 100);
      if (!isNaN(poDcPrice)) {
        this.poDcPrice = poDcPrice;
      }
      poNetPrice = parseFloat(this.poTotalPrice) - parseFloat(this.poDcPrice);
      if (!isNaN(poNetPrice)) {
        this.poNetPrice = poNetPrice;
      }

      poVatPrice = (this.poNetPrice / 100) * parseFloat(this.poVatPer);
      if (!isNaN(poVatPrice)) {
        this.poVatPrice = poVatPrice;
      }

      poGrandTotalPrice =
        parseFloat(this.poNetPrice) + parseFloat(this.poVatPrice);
      if (!isNaN(poGrandTotalPrice)) {
        this.poGrandTotalPrice = poGrandTotalPrice;
      }
    },
    calculateProduct(item) {
      item.totalPrice = 1 * parseFloat(item.pricePerOne);
      item.dcPrice =
        (parseFloat(item.totalPrice) / 100) * parseFloat(item.dcPer);
      item.netPrice = parseFloat(item.totalPrice) - parseFloat(item.dcPrice);
      this.prodId = item.prodId;
      this.productPricePerOne = parseFloat(this.poItem.pricePerOne);
      this.poItem.poTotalPrice = 1 * parseFloat(this.poItem.pricePerOne);
      // parseFloat(this.poItem.amount) * parseFloat(this.poItem.pricePerOne);
      // this.productDcPer = (this.poItem.pricePerOne * 100) / 7
      this.poItem.poDcPrice =
        parseFloat(this.poItem.poTotalPrice / 100) *
        parseFloat(this.poItem.poDcPer);
      this.poItem.poNetPrice =
        parseFloat(this.poItem.poTotalPrice) -
        parseFloat(this.poItem.poDcPrice);
      this.poItem.vatPrice =
        (parseFloat(this.poItem.poNetPrice) / 100) * parseFloat(this.poVatPer);
      this.poItem.poGrandTotalPrice =
        parseFloat(this.poItem.poNetPrice) + parseFloat(this.poItem.vatPrice);
      this.calculatePo();
    },
    getPoData() {
      this.isLoading = true; //skeleton true
      useNetw
        .get("api/received-vehicle/purchase-order", {
          params: {
            page: 1,
            perPage: 100,
            rcvdId: atob(this.$route.params.rcvdId),
            poCode: this.poCode,
            // branchId: this.branchId,
          },
        })
        .then((response) => {
          // console.log("Master Stock ==> " , response.data.data);
          this.optionsPO = response.data.data;
          this.$refs["modalPO"].show();

          // console.log(this.optionsPO);
          // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message + "<br>" + err.response.data.message
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.isLoading = false; //skeleton false
        });
    },
    handleChangePagePO(pagePO) {
      this.currentPagePO = pagePO;
      this.getPoData();
    },
    handlePageChangePO(active) {
      this.perPagePO = active;
      this.currentPagePO = 1;
      this.getPoData();
    },
    onFilteredPO(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowPO = filteredItems.length;
      this.currentPagePO = 1;
    },
    onRowSelectedPO(rowTest) {
      this.selected = rowTest;
      // this.getData();
    },
    // deleteVehicle(rowsPO) {
    //   this.vehicleId = rowsPO;
    //   this.overlayFlag = true;
    //   useNetw
    //     .delete("api/vehicle/delete", {
    //       data: {
    //         vehicleId: this.vehicleId,
    //       },
    //     })
    //     .then((response) => {
    //       Swal.fire(
    //         appConfig.swal.title.deleterSuccess,
    //         JSON.stringify(response.data.message),
    //         appConfig.swal.type.success
    //       );
    //     //   this.getData();
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },
    // alertDelete(vehicleId) {
    //   const swalWithBootstrapButtons = Swal.mixin({
    //     customClass: {
    //       confirmButton: "btn btn-success",
    //       cancelButton: "btn btn-danger ms-2",
    //     },
    //     buttonsStyling: false,
    //   });

    //   swalWithBootstrapButtons
    //     .fire({
    //       title: "ต้องการลบหรือไม่?",
    //       text: "ต้องการ Vehicle นี้หรือไม่ !!",
    //       icon: "warning",
    //       confirmButtonText: "OK",
    //       cancelButtonText: "Cancel!",
    //       showCancelButton: true,
    //     })
    //     .then((result) => {
    //       if (result.value) {
    //         this.deleteVehicle(vehicleId);
    //       } else if (
    //         /* Read more about handling dismissals below */
    //         result.dismiss === Swal.DismissReason.cancel
    //       ) {
    //         swalWithBootstrapButtons.fire(
    //           "Cancelled",
    //           "ยกเลิกเรียบร้อยแล้ว",
    //           "error"
    //         );
    //       }
    //     });
    // },
  },
  middleware: "authentication",
};
</script>
<style>
.alert-fixed {
  position: fixed;
  top: 50px;
  left: 65%;
  width: 30%;
  z-index: 9999;
  border-radius: 0px;
}

.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      dismissible
      fade
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
    </b-alert>
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div
              class="col-12 badge"
              style="background-color: #26B99A"
              v-if="this.status === 'APV'"
            >
              <h4 class="mt-2 text-white">
                รับรถเข้าคลังแล้ว
              </h4>
            </div>
            <div
              class="col-12 badge"
              style="background-color: #50a5f1"
              v-if="this.status === 'SUBMIT'"
            >
              <h4 class="mt-2 text-white">
                รอยืนยันการรับรถเข้าคลัง
              </h4>
            </div>
            <div
              class="col-12 badge"
              style="background-color: #e74646"
              v-if="this.status === 'CAN'"
            >
              <h4 class="mt-2 text-white">
                ยกเลิกแล้ว
              </h4>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            <code> * </code>สาขา:
                            <multiselect
                              v-model="userBranchId"
                              label="nameTh"
                              type="search"
                              :options="optionBranch"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                              @input="getFunction"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            <code> * </code>ผู้ผลิต:
                            <multiselect
                              v-model="supId"
                              label="nameTh"
                              type="search"
                              :options="rowsSuppliers"
                              :show-labels="false"
                              :loading="loading"
                              open-direction="bottom"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            <code> * </code>คลัง:
                            <multiselect
                              v-model="stock"
                              label="nameTh"
                              type="search"
                              :options="rowsStock"
                              :loading="loading"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            เลขที่จัดส่ง:
                            <b-form-input
                              v-model="shippingNumber"
                              type="search"
                              class="form-control"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            วันที่จัดส่ง:
                            <date-picker
                              v-model="shippingDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                            ></date-picker>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            เลขกำกับใบภาษี:
                            <b-form-input
                              v-model="taxInvoiceNumber"
                              type="search"
                              class="form-control"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-8 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            วันที่ออกกำกับใบภาษี:
                            <date-picker
                              v-model="taxInvoiceDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                            ></date-picker>
                          </div>
                        </div>
                        <div class="col-4 col-sm-6 col-md-1">
                          <div class="mb-3 position-relative">
                            เครดิต:
                            <b-form-input
                              v-model="credit"
                              type="search"
                              class="form-control"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-8 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            วันครบกำหนดชำระ:
                            <date-picker
                              v-model="dueDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                            ></date-picker>
                          </div>
                        </div>
                        <div class="col-8 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <code> * </code>วันที่รับรถเข้าคลัง:
                            <date-picker
                              v-model="rcvdDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                            ></date-picker>
                          </div>
                        </div>
                        <!-- <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 text-end">
                            <b-button
                              v-if="this.$route.params.rcvdId"
                              class="btn m-2"
                              variant="primary"
                              type="submit"
                              @click="updateReceived"
                            >
                              บันทึกการแก้ไขข้อมูล
                            </b-button>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <p class="card-title-desc">รายการสินค้า</p>
                </div>
                <div class="col-6">
                  <!-- <p
                    class="badge bg-success font-size-14 float-end"
                    v-if="this.status === 'APV'"
                  >
                    สั่งซื้อรถแล้ว
                  </p>
                  <p
                    class="badge bg-warning font-size-14 float-end"
                    v-if="this.status === 'DFT'"
                  >
                    ร่าง
                  </p>
                  <p
                    class="badge bg-danger font-size-14 float-end"
                    v-if="this.status === 'CAN'"
                  >
                    ยกเลิกแล้ว
                  </p> -->
                  <!-- <b-button class="float-end btn btn-success">
                    สั่งซื้อรถเพิ่ม
                  </b-button> -->
                </div>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">รหัสสินค้า</th>
                      <th scope="col">ชื่อรถ</th>
                      <th scope="col">เลขตัวถัง</th>
                      <th scope="col">สีภายนอก</th>
                      <th scope="col">สีภายใน</th>
                      <!-- <th scope="col">ราคา/หน่วย</th> -->
                      <!-- <th scope="col">จำนวน</th> -->
                      <th scope="col">รวม</th>
                      <th scope="col">ส่วนลด</th>
                      <th scope="col">ส่วนลดยอด</th>
                      <th scope="col">หลังลด</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody v-if="this.rowsDetail.length > 0">
                    <tr v-for="(poItem, index) in rowsDetail" :key="index">
                      <th scope="row">{{ index + 1 }}</th>
                      <td>{{ poItem.prodCode }}</td>
                      <td>{{ poItem.modelNameTh }}</td>
                      <td>{{ poItem.vin }}</td>
                      <td>
                        {{
                          poItem.exteriorColorNameTh != null
                            ? poItem.exteriorColorNameTh
                            : "-"
                        }}
                      </td>
                      <td>
                        {{
                          poItem.interiorColorNameTh != null
                            ? poItem.interiorColorNameTh
                            : "-"
                        }}
                      </td>
                      <!-- <td class="">
                        <input
                          v-model="poItem.pricePerOne"
                          class="form-control text-end number"
                          :disabled="status == 'APV'"
                          type="number"
                          @keyup.enter="updateDetailReceived(poItem)"
                          @input="calculateProduct(poItem)"
                          @change="calculateProduct(poItem)"
                        />
                      </td> -->

                      <!-- <td class="">
                        <input
                          :disabled="status == 'APV'"
                          v-model="poItem.amount"
                          type="number"
                          class="form-control text-end Dc"
                          @keyup.enter="updateDetailVehiclePO(poItem)"
                          @input="calculateProduct(poItem)"
                          @change="calculateProduct(poItem)"
                        />
                      </td> -->
                      <td class="text-end">
                        <input
                          style="width: 130px"
                          disabled
                          v-model="poItem.totalPrice"
                          class="form-control text-end number"
                          @input="calculateProduct(poItem)"
                          @change="calculateProduct(poItem)"
                        />
                      </td>
                      <td class="">
                        <input
                          style="width: 90px"
                          :disabled="status == 'APV'"
                          v-model="poItem.dcPer"
                          class="form-control text-end Dc"
                          type="number"
                          @keyup.enter="updateDetailReceived(poItem)"
                          @input="calculateProduct(poItem)"
                          @change="updateDetailReceived(poItem)"
                        />
                      </td>
                      <td class="text-end" @change="calculateProduct(poItem)">
                        <input
                          disabled
                          style="width: 120px"
                          v-model="poItem.dcPrice"
                          class="form-control text-end number"
                          @input="calculateProduct(poItem)"
                          @change="calculateProduct(poItem)"
                        />
                      </td>
                      <td class="text-end">
                        <input
                          disabled
                          style="width: 130px"
                          v-model="poItem.netPrice"
                          class="form-control text-end number"
                          @input="calculateProduct(poItem)"
                          @change="calculateProduct(poItem)"
                        />
                      </td>
                      <td v-if="status == 'DFT'">
                        <ul class="list-inline mb-0 action">
                          <li class="list-inline-item">
                            <a
                              class="px-2 text-danger"
                              v-b-tooltip.hover
                              title="Delete"
                              @click="alert(poItem, index)"
                            >
                              <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <p class="card-title-desc">ค้นหารายการสินค้า</p>

              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <!-- <div class="row">
                      <span class="text-center font-size-16">ไม่พบรายการ</span>
                    </div>
                    <hr /> -->
                    <div class="row">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3 position-relative">
                            <label for="partsCode"
                              ><code>*</code> ค้นหารหัสรถ,ชื่อรถ</label
                            >
                            <multiselect
                              id="partsCode"
                              v-model="searchform"
                              :options="rowsProduct"
                              label="prodCode"
                              :custom-label="customLabel"
                              :show-labels="false"
                              track-by="prodCode"
                              @input="showModal(searchform)"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              @search-change="getBranchProduct($event)"
                              :disabled="status == 'APV'"
                            >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-3">
                          <label for="parts">&nbsp;</label><br />
                          <button
                            :disabled="status == 'APV'"
                            class="btn btn-primary"
                            @click="$refs['modalPO'].show()"
                          >
                            <i class="uil uil-search"> </i> เลือกจากใบสั่งซื้อ
                          </button>
                        </div>

                        <div></div>
                      </div>
                    </div>
                    <hr />
                    <div class="card-body">
                      <h4 class="card-title">
                        <h4>
                          <strong>สรุปยอดการสั่งซื้อรถ</strong>
                        </h4>
                      </h4>
                      <hr />
                      <div class="row">
                        <div class="col-md-2 col-sm-3">
                          รายละเอียดการสั่งซื้อรถ
                        </div>
                        <div class="col-md-2 col-sm-3 col-6">
                          <div style="display: inline-block">
                            <div class="text-end">
                              ยอดรวมค่ารถ : <br />
                              ส่วนลดรวม(%) : <br />
                              ส่วนลดรวม(บาท) : <br />
                              จำนวนเงิน : <br />
                              ภาษีรวม(%) : <br />
                              ภาษีรวม(บาท) : <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-5 col-4 text-end">
                          <strong>
                            {{ this.numberFormats(this.poTotalPrice) }} <br />
                            {{ this.poDcPer }}<br />
                            {{ this.numberFormats(this.poDcPrice) }} <br />
                            {{ this.numberFormats(this.poNetPrice) }} <br />
                            {{ this.numberFormats(this.poVatPer) }} <br />
                            {{ this.numberFormats(this.poVatPrice) }}</strong
                          >
                        </div>
                        <div class="col-md-1 col-sm-2 col-2 text-end">
                          บาท<br />
                          %<br />
                          บาท <br />
                          บาท <br />
                          % <br />
                          บาท
                        </div>
                        <!-- มูลค่ารวม -->
                        <div
                          class="col-md-5 col-sm-5 bg-dark text-white m-0 row align-items-center"
                          style="border-radius:10px;margin:auto;justify "
                        >
                          <div class="row align-items-center m-0">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-md-3">มูลค่ารวม :</div>
                                <div class="col-6 font-size-24 text-center">
                                  <strong>{{
                                    Number(
                                      this.poGrandTotalPrice
                                    ).toLocaleString()
                                  }}</strong>
                                </div>
                                <div class="col-3">บาท</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br /><br />
                      <div class="row mb-4 mt-4">
                        <div class="col text-end ms-1">
                          <b-button
                            v-if="this.$route.params.rcvdId"
                            :disabled="status == 'APV' || status == 'SUBMIT'"
                            class="btn m-2"
                            variant="primary"
                            type="submit"
                            @click="updateReceived"
                          >
                            บันทึกการแก้ไขข้อมูล
                          </b-button>
                          <button
                            v-if="status == 'DFT'"
                            :disabled="status == 'SUBMIT'"
                            class="btn btn-danger ms-1"
                            @click="submitReceived()"
                          >
                            บันทึกการรับรถ
                          </button>
                          <button
                            v-if="status == 'SUBMIT' || status == 'APV'"
                            :disabled="status == 'APV'"
                            class="btn btn-danger ms-1"
                            @click="alertSubmit()"
                          >
                            ยืนยันการรับรถเข้าคลัง
                          </button>
                          <p>
                            <!-- <code v-if="this.part.status == 'APV'"
                              >***
                              ไม่สามารถบันทึกได้เนื่องจากอยู่ในสถานะยืนยันแล้ว</code
                            > -->
                            <code
                              >*** ถ้ายืนยันการรับรถเข้าคลังแล้ว
                              จะไม่สามารถแก้ไขข้อมูลได้</code
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
      <b-modal
        ref="modalAmout"
        title="ระบุจำนวน"
        hide-footer
        size="sm"
        centered
        :state="nameState"
      >
        <div class="row">
          <!-- <label for="amount"><code>*</code> จำนวน</label> -->
          <span class="badge bg-success mb-3">
            <h5 class="mt-2 text-white">({{ this.poItem.nameTh }})</h5>
            <h6 class="mt-2 text-white">{{ this.poItem.prodCode }}</h6>
            <h6 class="mt-2 text-white">ราคา {{ this.poItem.pricePerOne }}</h6>
          </span>
          <div class="col-6">
            <label> สีภายนอก </label>
            <multiselect
              v-model="exteriorColor"
              label="nameTh"
              type="search"
              :options="optionExteriorColor"
              :show-labels="false"
              open-direction="bottom"
              placeholder=""
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
          </div>
          <div class="col-6">
            <label> สีภายใน </label>
            <multiselect
              v-model="interiorColor"
              label="nameTh"
              type="search"
              :options="optioninteriorColor"
              :show-labels="false"
              open-direction="bottom"
              placeholder=""
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
          </div>
          <div class="col-12 col-sm-6 col-md-12 mt-2 mb-2">
            <label> <code> * </code> เลขตัวถัง</label>
            <input
              :state="nameState"
              style="text-align: center"
              id="poDcPer"
              v-model="vin"
              type="text"
              class="form-control"
              value="Otto"
              :class="{
                'is-invalid': submitted && $v.vin.$error,
              }"
            />
            <div v-if="submitted && $v.vin.$error" class="invalid-tooltip">
              <span v-if="!$v.vin.required">{{ message }}</span>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-12 mt-2 mb-2">
            <label> เลขตัวถัง</label>
            <input
              :state="nameState"
              style="text-align: center"
              id="poDcPer"
              v-model="chassisNumber"
              type="text"
              class="form-control"
              value="Otto"
              :class="{
                'is-invalid': submitted && $v.chassisNumber.$error,
              }"
            />
            <div
              v-if="submitted && $v.chassisNumber.$error"
              class="invalid-tooltip"
            >
              <span v-if="!$v.chassisNumber.required">{{ message }}</span>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-12 mt-2 mb-2">
            <label> เลขเครื่องยนต์</label>
            <input
              :state="nameState"
              style="text-align: center"
              id="poDcPer"
              v-model="engineNumber"
              type="text"
              class="form-control"
              value="Otto"
              :class="{
                'is-invalid': submitted && $v.engineNumber.$error,
              }"
            />
            <div
              v-if="submitted && $v.engineNumber.$error"
              class="invalid-tooltip"
            >
              <span v-if="!$v.engineNumber.required">{{ message }}</span>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-12 mt-2 mb-2">
            <label> <code> * </code> ส่วนลด(%)</label>
            <input
              :state="nameState"
              style="text-align: center"
              id="poDcPer"
              v-model="poItem.poDcPer"
              type="number"
              class="form-control"
              value="Otto"
              @input="calculateProduct(poItem)"
              @change="calculateProduct(poItem)"
              :class="{
                'is-invalid': submitted && $v.poItem.amount.$error,
              }"
            />
            <div
              v-if="submitted && $v.poItem.amount.$error"
              class="invalid-tooltip"
            >
              <span v-if="!$v.poItem.amount.required">{{ message }}</span>
            </div>
          </div>
          <!-- <div class="col-6 mt-2 mb-2">
            <label> <code> * </code> จำนวน</label>
            <input
              :state="nameState"
              style="text-align: center"
              id="amount"
              v-model="poItem.amount"
              type="number"
              class="form-control"
              value="Otto"
              @input="calculateProduct(poItem)"
              @change="calculateProduct(poItem)"
              :class="{
                'is-invalid': submitted && $v.poItem.amount.$error,
              }"
            />
            <div
              v-if="submitted && $v.poItem.amount.$error"
              class="invalid-tooltip"
            >
              <span v-if="!$v.poItem.amount.required">{{ message }}</span>
            </div>
          </div> -->
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="text-end">
              <button
                @click="addDetailReceived(poItem)"
                class="btn btn-primary"
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal
        title="เลือกใบสั่งซื้ออะไหล่"
        hide-footer
        size="xl"
        centered
        ref="modalPO"
      >
        <div class="row">
          <div class="col-md-10">
            <strong><code>* </code>รหัสใบสั่งซื้ออะไหล่</strong>
            <div class="row">
              <div class="col-md-5">
                <input
                  :disabled="status == 'APV'"
                  type="text"
                  v-model="poCode"
                  class="form-control"
                />
              </div>
              <div class="col-md-auto">
                <button
                  class="btn btn-primary"
                  @click="getPoData"
                  :disabled="status == 'APV'"
                >
                  ค้นหาด้วยใบ PO
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <div class="mb-6 position-relative">
              <div class="table-responsive mb-0">
                <b-table
                  :items="optionsPO"
                  :fields="fieldsPartPO"
                  responsive="sm"
                  :per-page="perPagePO"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filterPO"
                  :filter-included-fields="filterOnPO"
                  @filtered="onFilteredPO"
                  ref="selectableTablePO"
                  selectable
                  select-mode="single"
                  @row-selected="onRowSelectedPO"
                >
                  <template v-slot:cell(action)="optionsPO">
                    <a
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                      @click="showModalPO(optionsPO)"
                    >
                      <i class="uil uil-plus-square font-size-18"></i>
                      เพิ่มเข้าคลัง
                    </a>
                  </template>

                  <!-- End edit table --> </b-table
                ><br />

                <div class="row">
                  <!-- <div class="col">
                                แสดงผล {{ vmFrom }} - {{ vmTo }} of {{ totalVm }} rows
                              </div> -->

                  <div class="col">
                    <div
                      class="
                                  dataTables_paginate
                                  paging_simple_numbers
                                  float-end
                                "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPagePO"
                          :total-rows="totalPO"
                          :per-page="perPagePO"
                          @change="handleChangePagePO"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </Layout>
</template>
